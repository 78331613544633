import axios from "axios";
import { auth } from "./firebase";
import { CONFIG_KEYS } from "./constants";

const axiosClient = axios.create({
  baseURL: CONFIG_KEYS.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true, // Important for CORS with credentials
});

axiosClient.interceptors.request.use(async function (request) {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.warn("No current user found in interceptor");
      return request;
    }

    // Force token refresh
    const idToken = await currentUser.getIdToken(true);
    request.headers["Authorization"] = `Bearer ${idToken}`;

  } catch (error) {
    console.error("Error obtaining auth token in interceptor:", error);
  }

  return request;
});

export default axiosClient;
