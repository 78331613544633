import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, Grid, Typography, Box, FormControl, TextField } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/OverlayLoader";
import { DataGrid } from "@mui/x-data-grid";
import { NumericFormat } from "react-number-format";
import catalyticConverterService from "../services/catalyticConverterService";
import Zoom from "react-medium-image-zoom";

export const CatalyticConverters = () => {
  const [search, setSearch] = useState("");

  const { data: catalyticConverters, isFetching: catsLoading, refetch } = useQuery([QUERIES.CatalyticConverters], () => catalyticConverterService.getSimple(search));

  const columns = [
    {
      headerName: "Name",
      field: "code",
      width: 300,
    },
    {
      headerName: "Price",
      field: "price",
      width: 150,
      renderCell: (params) => {
        const { row } = params;

        return <NumericFormat value={row?.price} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />;
      },
    },
    {
      headerName: "Images",
      field: "images",
      width: 400,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box>
            {row?.imageSets?.map((imageSet) => {
              return (
                <Box display="flex">
                  {imageSet?.frontImage && (
                    <Zoom key={`cat-image-${imageSet?.frontImage?.id}`} zoomImg={{ src: imageSet?.frontImage?.url }}>
                      <Box sx={{ maxHeight: 80, maxWidth: 80 }} component="img" src={imageSet?.frontImage?.thumbnailUrl} />
                    </Zoom>
                  )}
                  {imageSet?.backImage && (
                    <Zoom key={`cat-image-${imageSet?.backImage?.id}`} zoomImg={{ src: imageSet?.backImage?.url }}>
                      <Box sx={{ maxHeight: 80, maxWidth: 80 }} component="img" src={imageSet?.backImage?.thumbnailUrl} />
                    </Zoom>
                  )}
                  {imageSet?.codeImage && (
                    <Zoom key={`cat-image-${imageSet?.codeImage?.id}`} zoomImg={{ src: imageSet?.codeImage?.url }}>
                      <Box sx={{ maxHeight: 80, maxWidth: 80 }} component="img" src={imageSet?.codeImage?.thumbnailUrl} />
                    </Zoom>
                  )}
                  {imageSet?.images?.map((image) => {
                    return (
                      <Zoom key={`cat-image-${image?.id}`} zoomImg={{ src: image?.url }}>
                        <Box sx={{ maxHeight: 80, maxWidth: 80 }} component="img" src={image?.thumbnailUrl} />
                      </Zoom>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        );
      },
    },
    {
      headerName: "Samples",
      field: "samples",
      width: 1000,
      renderCell: (params) => {
        const { row } = params;

        var output = <NumericFormat value={row?.price} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />;

        if (row?.samples?.length > 0) {
          output = row?.samples?.map((sample) => {
            return (
              <Box mr={1}>
                <Typography>
                  <NumericFormat value={sample?.price} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
                </Typography>
              </Box>
            );
          });
        }

        return output;
      },
    },
  ];

  const isLoading = catsLoading;

  return (
    <>
      <OverlayLoader isLoading={isLoading} />
      <Grid container px={4} pt={2} pb={3} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <Button size="large" sx={{ mb: 1 }} startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to="/manifests">
            Back
          </Button>
          <Typography mt={1} variant="h4" color="text.primary">
            Catalytic Converter Overview
          </Typography>
        </Grid>
        <Grid
          item
          mt={5}
          pl={2}
          xs={12}
          container
          alignItems="center"
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();
            refetch();
          }}>
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <TextField name="search" id="search" value={search} onChange={(e) => setSearch(e.target.value)} label="Search" />
            </FormControl>
          </Grid>
          <Grid item>
            <Button disabled={search === "" || isLoading} type="submit" size="large" sx={{ m: 1 }} variant="contained" color="primary">
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container px={4} pt={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <Typography variant="h5" color="text.primary">
                    Results
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontStyle: "italic",
                    }}>
                    Showing {catalyticConverters?.data?.result?.length} Results
                  </Typography>
                </>
              }
            />
            <CardContent>
              {catalyticConverters?.data?.result && <DataGrid sx={{ mt: 4 }} getRowHeight={() => "auto"} rows={catalyticConverters?.data?.result} columns={columns} />}
              {catalyticConverters?.data?.result?.length === 0 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
                  <Typography variant="h3">No Catalytic Converters</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
