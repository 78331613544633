import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, Grid, Typography, Box, Dialog, TextField, IconButton, FormControlLabel, FormGroup, Switch, Chip } from "@mui/material";
import { Add, ChevronLeft, Close, Edit } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/OverlayLoader";
import moment from "moment";
import UserService from "../services/UserService";
import { useFormik } from "formik";
import * as yup from "yup";
import { useConfirm } from "material-ui-confirm";
import { DataGrid } from "@mui/x-data-grid";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const Users = () => {
  const { data: users, isFetching: usersLoading } = useQuery([QUERIES.Users], () => UserService.get());

  const queryClient = useQueryClient();

  const confirm = useConfirm();

  const createNewUserMutation = useMutation((newUser) => UserService.createUser(newUser));
  const updateUserPermissionsMutation = useMutation((user) => UserService.updateUserPermissions(selectedUser?.id, user));

  const [showDialog, setshowDialog] = useState(false);
  const [showPermissionsDialog, setshowPermissionsDialog] = useState(false);
  const [selectedUser, setselectedUser] = useState(null);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await createNewUserMutation.mutateAsync(values);

        queryClient.invalidateQueries(QUERIES.Users);

        setshowDialog(false);
      } catch (error) {
        formik.setErrors({ email: "Invalid email or password", password: "Invalid email or password" });
      }
    },
  });

  const permissionsFormik = useFormik({
    initialValues: {
      admin: false,
      ue: false,
      lg: false,
      ue_catId: false,
      ue_vehicles: false,
      cl: false,
      cc: false,
      percentage: 100,
    },
    onSubmit: async (values) => {
      await confirm({
        title: "Adjust Permissions",
        description: `Are you sure you want to adjust the permissions for ${selectedUser?.email}?`,
        confirmationText: "Yes",
        cancellationText: "No",
      });

      await updateUserPermissionsMutation.mutateAsync(values);

      queryClient.invalidateQueries(QUERIES.Users);

      setshowPermissionsDialog(false);
      setselectedUser(null);
    },
  });

  const columns = [
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box key={`actions-${row.id}`}>
            <Button
              startIcon={<Edit />}
              onClick={() => {
                setselectedUser(row);
                var formikValues = {
                  ...row?.claims,
                  percentage: row?.percentage,
                };
                permissionsFormik.setValues(formikValues);
                setshowPermissionsDialog(true);
              }}
              variant="contained"
              size="small"
              color="primary">
              Edit
            </Button>
          </Box>
        );
      },
    },
    {
      headerName: "Id",
      field: "id",
      width: 100,
    },
    {
      headerName: "Email",
      field: "email",
      width: 300,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box
            key={`email-${row.id}`}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}>
            <Typography>{row?.email}</Typography>
            <Typography color="text.secondary" variant="caption">
              {moment.utc(row?.created).local().format("MM/DD/yyyy hh:mm A")}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "Percentage",
      field: "percentage",
      width: 100,
      renderCell: (params) => {
        const { row } = params;

        return <Typography key={`percentage-${row.id}`}>{row?.percentage}%</Typography>;
      },
    },
    {
      headerName: "Permissions",
      field: "claims",
      width: 1200,
      renderCell: (params) => {
        const { row } = params;

        return (
          <Box key={`permissions-${row.id}`} display="flex" gap={1}>
            {row?.claims?.admin === true && <Chip key={`admin-${row.id}`} label="Admin" color="primary" />}
            {row?.claims?.ue === true && <Chip key={`ue-${row.id}`} label="United Ecosystem" color="primary" />}
            {row?.claims?.ue_catId === true && <Chip key={`ue_catId-${row.id}`} label="UE Converter ID" color="primary" />}
            {row?.claims?.ue_vehicles === true && <Chip key={`ue_vehicles-${row.id}`} label="UE Vehicles" color="primary" />}
            {row?.claims?.lg === true && <Chip key={`lg-${row.id}`} label="Lead Generation" color="primary" />}
            {row?.claims?.cl === true && <Chip key={`cl-${row.id}`} label="Cat Lab" color="primary" />}
            {row?.claims?.cc === true && <Chip key={`cc-${row.id}`} label="Control Center" color="primary" />}
            {row?.claims?.cc_apiKeys === true && <Chip key={`cc_apiKeys-${row.id}`} label="API Key Management" color="primary" />}
          </Box>
        );
      },
    },
  ];

  const isLoading = usersLoading || createNewUserMutation.isLoading;

  return (
    <>
      <Grid container px={4} pt={2} pb={3} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <Button size="large" sx={{ mb: 1 }} startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to="/manifests">
            Back
          </Button>
          <Typography mt={1} variant="h4" color="text.primary">
            Users Overview
          </Typography>
        </Grid>
      </Grid>
      <Grid container px={4} pt={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <Typography variant="h5" color="text.primary">
                    Results
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontStyle: "italic",
                    }}>
                    Showing {users?.data?.result?.length} Results
                  </Typography>
                </>
              }
              action={
                <>
                  <Button onClick={() => setshowDialog(true)} variant="contained" startIcon={<Add />}>
                    Create User
                  </Button>
                </>
              }
            />
            <CardContent>
              {users?.data?.result && (
                <DataGrid
                  rows={users?.data?.result || []}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableColumnMenu
                  disableColumnFilter
                  disableSelectionOnClick
                  autoHeight
                  getRowId={(row) => row.id}
                  sx={{ border: "none" }}
                />
              )}
              {users?.data?.result?.length === 0 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
                  <Typography variant="h3">No Users</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={showDialog} onClose={() => setshowDialog(false)}>
        <Box px={2} py={3}>
          <Typography variant="h4">Create User</Typography>
          <Typography variant="body1">Enter the email and password for the new user below.</Typography>
          <IconButton onClick={() => setshowDialog(false)} sx={{ position: "absolute", top: 8, right: 8 }}>
            <Close />
          </IconButton>

          <Box mt={2}>
            <form onSubmit={formik.handleSubmit}>
              <TextField fullWidth name="email" label="Email Address" value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} margin="normal" />
              <TextField fullWidth name="password" label="Password" value={formik.values.password} onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)} helperText={formik.touched.password && formik.errors.password} margin="normal" />
              <Button sx={{ mt: 3 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                Create User
              </Button>
            </form>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={showPermissionsDialog} onClose={() => setshowPermissionsDialog(false)}>
        <Box px={2} py={3}>
          <Typography variant="h4">Adjust Permissions</Typography>
          <Typography variant="body1">Select the permissions for the user below.</Typography>
          <IconButton onClick={() => setshowPermissionsDialog(false)} sx={{ position: "absolute", top: 8, right: 8 }}>
            <Close />
          </IconButton>

          <Box mt={2}>
            <form onSubmit={permissionsFormik.handleSubmit}>
              <FormGroup>
                <Box>
                  <Typography>External Apps</Typography>
                  <FormControlLabel control={<Switch name="ue" checked={permissionsFormik.values.ue} onChange={(event) => permissionsFormik.setFieldValue("ue", event.target.checked)} />} label="United Ecosystem" />
                  <FormControlLabel control={<Switch name="ue_catId" checked={permissionsFormik.values.ue_catId} onChange={(event) => permissionsFormik.setFieldValue("ue_catId", event.target.checked)} />} label="UE Converter ID" />
                  <FormControlLabel control={<Switch name="ue_vehicles" checked={permissionsFormik.values.ue_vehicles} onChange={(event) => permissionsFormik.setFieldValue("ue_vehicles", event.target.checked)} />} label="UE Vehicles" />
                </Box>
                <Box>
                  <Typography>Internal Apps</Typography>
                  <FormControlLabel control={<Switch name="lg" checked={permissionsFormik.values.lg} onChange={(event) => permissionsFormik.setFieldValue("lg", event.target.checked)} />} label="Lead Generation" />
                  <FormControlLabel control={<Switch name="cl" checked={permissionsFormik.values.cl} onChange={(event) => permissionsFormik.setFieldValue("cl", event.target.checked)} />} label="Cat Lab" />
                  <FormControlLabel control={<Switch name="cc" checked={permissionsFormik.values.cc} onChange={(event) => permissionsFormik.setFieldValue("cc", event.target.checked)} />} label="Control Center" />
                  <FormControlLabel control={<Switch name="admin" checked={permissionsFormik.values.admin} onChange={(event) => permissionsFormik.setFieldValue("admin", event.target.checked)} />} label="Admin" />
                  <FormControlLabel control={<Switch name="cc_apiKeys" checked={permissionsFormik.values.cc_apiKeys} onChange={(event) => permissionsFormik.setFieldValue("cc_apiKeys", event.target.checked)} />} label="API Key Management" />
                </Box>
              </FormGroup>
              <FormGroup>
                <TextField
                  fullWidth
                  name="percentage"
                  label="Percentage"
                  type="number"
                  required
                  value={permissionsFormik.values.percentage}
                  onChange={permissionsFormik.handleChange}
                  error={permissionsFormik.touched.percentage && Boolean(permissionsFormik.errors.percentage)}
                  helperText={permissionsFormik.touched.percentage && permissionsFormik.errors.percentage}
                  margin="normal"
                />
              </FormGroup>
              <Button sx={{ mt: 3 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={permissionsFormik.isSubmitting}>
                Save
              </Button>
            </form>
          </Box>
        </Box>
      </Dialog>
      <OverlayLoader isLoading={isLoading} />
    </>
  );
};
