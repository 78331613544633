import { Delete } from "@mui/icons-material";
import { Alert, Box, Button, Chip, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from "notistack";
import React, { useState } from "react";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import manifestService from "../services/manifestService";
import { QUERIES } from "../utils/constants";
import ManifestEntryConverterDetails from "./ManifestEntryConverterDetails";
import SavageConverterSelect from "./SavageConverterSelect";
import SamplesSelect from "./SamplesSelect";

const SubmissionTable = ({ manifestEntry, manifest }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedConverter, setSelectedConverter] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const disableMutation = useMutation((id) => manifestService.disableManifestEntryCat(id));

  const handleImageClick = (image, converter) => {
    setSelectedImage(image);
    setSelectedConverter(converter);
    setDetailsOpen(true);
  };

  const handleCloseDialog = () => {
    setDetailsOpen(false);
  };

  const updateCatEntryMutation = useMutation((values) => manifestService.updateEntryCat(values.id, values));

  const handleRemove = async (submission) => {
    confirm({ description: "This action is permanent!", title: "Are you sure you want to remove this submission?" })
      .then(async () => {
        await disableMutation.mutateAsync(submission.id);

        queryClient.invalidateQueries([QUERIES.ManifestEntries]);
        enqueueSnackbar("Submission Removed Successfully.", { variant: "success" });
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: "calc(100VH - 400px)" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6" color="primary">
                  Submissions
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" color="primary">
                  Code(s)
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {manifestEntry?.manifestEntryCatalyticConverters?.length > 0 &&
              manifestEntry?.manifestEntryCatalyticConverters
                ?.filter((x) => x.enabled)
                ?.map((submission) => {
                  var primaryCodeText = submission?.catalyticConverter?.primaryCode;

                  if (!primaryCodeText) primaryCodeText = submission?.catalyticConverterPrimaryCode;
                  if (!primaryCodeText) primaryCodeText = submission?.converterConnectionsCatalyticConverter?.code;
                  if (!primaryCodeText) primaryCodeText = "No Code";

                  if (submission?.converterConnectionsCatalyticConverter?.code) primaryCodeText = null;

                  return (
                    <TableRow key={submission.id}>
                      <TableCell>
                        <Typography>{submission?.user?.name}</Typography>
                        <Typography>
                          {moment.utc(submission?.created).local().format("MM/DD/yyyy hh:mm A")}
                        </Typography>
                        <Button onClick={() => handleRemove(submission)} color="error" variant="outlined" size="small" sx={{ mt: 1 }} endIcon={<Delete />}>
                          REMOVE
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography>{primaryCodeText}</Typography>
                          <Typography>{submission?.catalyticConverterNotes}</Typography>
                        </Box>
                        <Box mt={1}>
                          {submission?.converterConnectionsCatalyticConverter?.code ? (
                            <Chip
                              color="primary"
                              onDelete={async () => {
                                await updateCatEntryMutation.mutateAsync({
                                  id: submission.id,
                                  converterConnectionsConverterId: null,
                                });

                                queryClient.invalidateQueries([QUERIES.ManifestEntries]);
                                enqueueSnackbar("Catalytic Converter Removed Successfully.", { variant: "success" });
                              }}
                              label={submission?.converterConnectionsCatalyticConverter?.code}
                            />
                          ) : (
                            <>
                              <SavageConverterSelect
                                sx={{ minWidth: 300 }}
                                onChange={async (_event, newValue) => {
                                  await updateCatEntryMutation.mutateAsync({
                                    id: submission.id,
                                    converterConnectionsConverterId: newValue?.value ?? null,
                                  });

                                  queryClient.invalidateQueries([QUERIES.ManifestEntries]);
                                  enqueueSnackbar("Catalytic Converter Mapped Successfully.", { variant: "success" });
                                }}
                              />
                              <Alert sx={{ mt: 1 }} severity="warning">
                                No Converter Selected
                              </Alert>
                              <SamplesSelect
                                sx={{ minWidth: 300, mt: 2 }}
                                onChange={async (_event, newValue) => {
                                  await updateCatEntryMutation.mutateAsync({
                                    id: submission.id,
                                    sampleId: newValue?.sample?.sampleId ?? null,
                                  });

                                  queryClient.invalidateQueries([QUERIES.ManifestEntries]);
                                  enqueueSnackbar("Sample Mapped Successfully.", { variant: "success" });
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          {[submission?.frontImage, submission?.backImage, submission?.codeImage, submission?.stockTagImage].concat(submission?.images).map((image, index) => {
                            if (image) {
                              return <Box key={`${submission.id}-image-${index}`} sx={{ position: "relative", maxWidth: "150px", maxHeight: "150px", cursor: "pointer" }} component="img" src={image?.url} alt={`Image ${image?.description}`} onClick={() => handleImageClick(image, submission)} />;
                            }
                          })}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={detailsOpen} fullWidth maxWidth="lg" onClose={handleCloseDialog}>
        <ManifestEntryConverterDetails manifestEntry={manifestEntry} manifest={manifest} manifestConverter={selectedConverter} onClose={handleCloseDialog} initialImage={selectedImage} />
      </Dialog>
    </>
  );
};

export default SubmissionTable;
