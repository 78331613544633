import api from "../utils/axios-client";
/**
 * Gets all auto complete converters
 */
const getAutoCompleteConverters = async () => api.get("/v1/catalytic-converters/simple/auto-complete");

/**
 * Gets all cascading search data via list of a vins
 *
 */
const getCascadingSearch = async (vins) => api.post(`/v1/catalytic-converters/detailed/cascading-search/as-user`, vins);

/**
 * Syncs catalytic converter pricing
 * @param {Object} pgmPrice
 * @param {string} pgmPrice.platinumPrice
 * @param {string} pgmPrice.palladiumPrice
 * @param {string} pgmPrice.rhodiumPrice
 */
const syncCatPrices = async (pgmPrice) => api.post(`/v1/catalytic-converters/sync-pricing`, pgmPrice);

/**
 * Gets simple catalytic converter data
 * @param {string} search
 */
const getSimple = async (search) => api.get(`/v1/catalytic-converters/simple`, { params: { search } });

/**
 * Gets catalytic converter samples with cached prices
 * @param {string} search
 */
const getSamples = async (search) => api.get(`/v1/catalytic-converter-samples`, { params: { search } });

export default { getAutoCompleteConverters, getCascadingSearch, syncCatPrices, getSimple, getSamples };
