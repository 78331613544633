import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, Grid, Typography, Box, Dialog, TextField, IconButton } from "@mui/material";
import { Add, ChevronLeft, Close, Delete, CopyAll } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/OverlayLoader";
import moment from "moment";
import ApiKeyService from "../services/ApiKeyService";
import { useFormik } from "formik";
import * as yup from "yup";
import { useConfirm } from "material-ui-confirm";
import { DataGrid } from "@mui/x-data-grid";

const validationSchema = yup.object({
  name: yup.string().required("A name for the key is required."),
  description: yup.string().required("A description for the key is required."),
});

export const ApiKeys = () => {
  const { data: apiKeys, isFetching: apiKeysLoading } = useQuery([QUERIES.ApiKeys], () => ApiKeyService.getKeys());

  const queryClient = useQueryClient();

  const confirm = useConfirm();

  const createNewKeyMutation = useMutation((key) => ApiKeyService.createKey(key));
  const deleteKeyMutation = useMutation((keyId) => ApiKeyService.deleteKey(keyId));

  const [showDialog, setshowDialog] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await createNewKeyMutation.mutateAsync(values);

        queryClient.invalidateQueries(QUERIES.ApiKeys);

        setshowDialog(false);
        formik.resetForm();
      } catch (error) {}
    },
  });

  const deleteKey = async (key) => {
    confirm({ title: "Delete Key", description: `Are you sure you want to delete the ${key.name} API Key? It will no longer be usable!`, confirmationText: "Yes", cancellationText: "No" })
      .then(async () => {
        await deleteKeyMutation.mutateAsync(key.id);
        queryClient.invalidateQueries(QUERIES.ApiKeys);
      })
      .catch(() => {});
  };

  const columns = [
    {
      field: 'id',
      headerName: "Id",
      width: 100,
    },
    {
      field: 'name',
      headerName: "Name",
      width: 300,
      sortable: false,
      renderCell: (params) => {
        const { name, created } = params.row;
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{name}</div>
            <div style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '0.75rem' }}>
              Created: {moment.utc(created).local().format("MM/DD/yyyy hh:mm A")}
            </div>
          </div>
        );
      },
    },
    {
      field: 'description',
      headerName: "Description",
      width: 1000,
      sortable: false,
    },
    {
      field: 'key',
      headerName: "Key",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { key } = params.row;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => navigator.clipboard.writeText(key)} size="small">
              <CopyAll />
            </IconButton>
            <div style={{ marginTop: '4px' }}>{key}</div>
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => deleteKey(params.row)}
          size="small"
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  const isLoading = apiKeysLoading || createNewKeyMutation.isLoading || deleteKeyMutation.isLoading;

  return (
    <>
      <Grid container px={4} pt={2} pb={3} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <Button size="large" sx={{ mb: 1 }} startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to="/manifests">
            Back
          </Button>
          <Typography mt={1} variant="h4" color="text.primary">
            API Key Management
          </Typography>
        </Grid>
      </Grid>
      <Grid container px={4} pt={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <Typography variant="h5" color="text.primary">
                    Results
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontStyle: "italic",
                    }}
                  >
                    Showing {apiKeys?.data?.result?.length} Results
                  </Typography>
                </>
              }
              action={
                <>
                  <Button onClick={() => setshowDialog(true)} variant="contained" startIcon={<Add />}>
                    Generate New Key
                  </Button>
                </>
              }
            />
            <CardContent>
              {apiKeys?.data?.result && (
                <DataGrid
                  sx={{ mt: 4 }}
                  autoHeight={true}
                  rows={apiKeys?.data?.result}
                  columns={columns}
                  getRowId={(row) => row.id}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  disableColumnFilter
                  hideFooterSelectedRowCount
                  pageSizeOptions={[10]}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                />
              )}
              {apiKeys?.data?.result?.length === 0 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
                  <Typography variant="h3">No API Keys</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={showDialog} onClose={() => setshowDialog(false)}>
        <Box px={2} py={3}>
          <Typography variant="h4">Generate New API Key</Typography>
          <Typography variant="body1">Enter the name and description for the new API key below. It will then be generated.</Typography>
          <IconButton onClick={() => setshowDialog(false)} sx={{ position: "absolute", top: 8, right: 8 }}>
            <Close />
          </IconButton>

          <Box mt={2}>
            <form onSubmit={formik.handleSubmit}>
              <TextField fullWidth name="name" label="Name" value={formik.values.name} onChange={formik.handleChange} error={formik.touched.name && Boolean(formik.errors.name)} helperText={formik.touched.name && formik.errors.name} margin="normal" />
              <TextField fullWidth name="description" label="Description" value={formik.values.description} onChange={formik.handleChange} error={formik.touched.description && Boolean(formik.errors.description)} helperText={formik.touched.description && formik.errors.description} margin="normal" />
              <Button sx={{ mt: 3 }} size="large" color="primary" variant="contained" fullWidth type="submit" disabled={formik.isSubmitting}>
                Generate Key
              </Button>
            </form>
          </Box>
        </Box>
      </Dialog>
      <OverlayLoader isLoading={isLoading} />
    </>
  );
};
