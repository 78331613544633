import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardContent, CardHeader, Grid, Typography, Box, FormControl, TextField } from "@mui/material";
import { ChevronLeft, Refresh } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { QUERIES } from "../utils/constants";
import OverlayLoader from "../ui-components/OverlayLoader";
import moment from "moment";

import { DataGrid } from "@mui/x-data-grid";
import pgmPricesService from "../services/pgmPricesService";
import { NumericFormat } from "react-number-format";
import catalyticConverterService from "../services/catalyticConverterService";
import { useConfirm } from "material-ui-confirm";

const initalFilter = {
  platinumPrice: "",
  palladiumPrice: "",
  rhodiumPrice: "",
};

export const PgmPrices = () => {
  const [pgmPrice, setPgmPrice] = useState(initalFilter);
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const { data: pgmPrices, isFetching: pgmPricesLoading } = useQuery([QUERIES.PgmPrices], () => pgmPricesService.getAll());
  const { data: latestPgmPrices, isFetching: latestPgmPricesLoading } = useQuery([QUERIES.PgmPrices, "latest"], () => pgmPricesService.getLatest());

  const pgmPriceCreateMutation = useMutation((pgmPrice) => pgmPricesService.create(pgmPrice), {
    onSuccess: () => {
      setPgmPrice(initalFilter);
      queryClient.invalidateQueries(QUERIES.PgmPrices);
    },
  });

  const syncCats = useMutation(() => catalyticConverterService.syncCatPrices(latestPgmPrices?.data?.result));

  const columns = [
    {
      headerName: "Created",
      field: "created",
      width: 200,
      renderCell: (params) => {
        const { row } = params;

        return moment.utc(row?.created).local().format("MM/DD/yyyy hh:mm A");
      },
    },
    {
      headerName: "Platinum",
      field: "platinumPrice",
      width: 150,
      renderCell: (params) => {
        const { row } = params;

        return <NumericFormat value={row?.platinumPrice} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />;
      },
    },
    {
      headerName: "Palladium",
      field: "palladiumPrice",
      width: 150,
      renderCell: (params) => {
        const { row } = params;

        return <NumericFormat value={row?.palladiumPrice} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />;
      },
    },
    {
      headerName: "Rhodium",
      field: "rhodiumPrice",
      width: 150,
      renderCell: (params) => {
        const { row } = params;

        return <NumericFormat value={row?.rhodiumPrice} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />;
      },
    },
  ];

  const handleSyncCats = async () => {
    confirm({ description: "Are you sure you want to sync catalytic converter prices with the latest markets?" })
      .then(async () => {
        await syncCats.mutateAsync();
      })
      .catch(() => {});
  };

  const isLoading = pgmPricesLoading || latestPgmPricesLoading || pgmPriceCreateMutation.isLoading || syncCats.isLoading;

  return (
    <>
      <OverlayLoader isLoading={isLoading} />
      <Grid container px={4} pt={2} pb={3} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <Button size="large" sx={{ mb: 1 }} startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to="/manifests">
            Back
          </Button>
          <Typography mt={1} variant="h4" color="text.primary">
            Pgm Prices Overview
          </Typography>
        </Grid>
        <Grid
          item
          mt={5}
          pl={2}
          xs={12}
          container
          alignItems="center"
          as="form"
          onSubmit={async (e) => {
            e.preventDefault();
            await pgmPriceCreateMutation.mutateAsync(pgmPrice);
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Latest Markets</Typography>
          </Grid>
          <Grid item xs={12} container mb={2}>
            <Box display="flex" gap={2}>
              <Card sx={{ py: 3 }}>
                <Typography variant="h6">PT</Typography>
                <Typography variant="subtitle1">Platinum</Typography>
                <Typography variant="h6">
                  <NumericFormat value={latestPgmPrices?.data?.result?.platinumPrice} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
                </Typography>
              </Card>
              <Card sx={{ py: 3 }}>
                <Typography variant="h6">PD</Typography>
                <Typography variant="subtitle1">Palladium</Typography>
                <Typography variant="h6">
                  <NumericFormat value={latestPgmPrices?.data?.result?.palladiumPrice} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
                </Typography>
              </Card>
              <Card sx={{ py: 3 }}>
                <Typography variant="h6">RH</Typography>
                <Typography variant="subtitle1">Rhodium</Typography>
                <Typography variant="h6">
                  <NumericFormat value={latestPgmPrices?.data?.result?.rhodiumPrice} displayType="text" thousandSeparator={true} prefix={"$"} decimalScale={2} fixedDecimalScale={true} />
                </Typography>
              </Card>
            </Box>
            <Box display="flex" alignItems="center">
              <Button startIcon={<Refresh />} size="large" sx={{ m: 1 }} variant="contained" color="primary" onClick={handleSyncCats}>
                Sync Cat Prices With Latest Markets
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">New Markets</Typography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <TextField name="platinumPrice" id="platinumPrice" value={pgmPrice.platinumPrice} onChange={(e) => setPgmPrice({ ...pgmPrice, platinumPrice: e.target.value })} label="Platinum (PT)" />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <TextField name="palladiumPrice" id="palladiumPrice" value={pgmPrice.palladiumPrice} onChange={(e) => setPgmPrice({ ...pgmPrice, palladiumPrice: e.target.value })} label="Palladium (PD)" />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1 }}>
              <TextField name="rhodiumPrice" id="rhodiumPrice" value={pgmPrice.rhodiumPrice} onChange={(e) => setPgmPrice({ ...pgmPrice, rhodiumPrice: e.target.value })} label="Rhodium (RH)" />
            </FormControl>
          </Grid>
          <Grid item>
            <Button disabled={pgmPrice.platinumPrice === "" || pgmPrice.palladiumPrice === "" || pgmPrice.rhodiumPrice === "" || pgmPriceCreateMutation.isLoading} type="submit" size="large" sx={{ m: 1 }} variant="contained" color="primary">
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container px={4} pt={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <Typography variant="h5" color="text.primary">
                    Results
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontStyle: "italic",
                    }}
                  >
                    Showing {pgmPrices?.data?.result?.length} Results
                  </Typography>
                </>
              }
            />
            <CardContent>
              {pgmPrices?.data?.result && <DataGrid sx={{ mt: 4 }} rows={pgmPrices?.data?.result} columns={columns} />}
              {pgmPrices?.data?.result?.length === 0 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
                  <Typography variant="h3">No Pgm Prices</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
