export const QUERIES = {
  Manifests: "manifests",
  ManifestEntries: "manifestEntries",
  ManifestEntryVehicles: "manifestEntryVehicles",
  ManifestStatus: "manifestStatus",
  ManifestEntryStatus: "manifestEntryStatus",
  ConverterConnectionsConverters: "converterConnectionsConverters",
  CatalyticConvertersCascading: "catalyticConvertersCascading",
  CatalyticConverters: "catalyticConverters",
  CatalyticConverterSamples: "catalyticConverterSamples",
  VehicleMakes: "vehicleMakes",
  VehicleYears: "vehicleYears",
  VehicleModels: "vehicleModels",
  VehicleEngines: "vehicleEngines",
  PgmPrices: "pgmPrices",
  Users: "users",
  ApiKeys: "apiKeys",
};

export const MANIFEST_STATUS_LABELS = {
  YEAR_MAKE_MODEL_ENGINE_CC: "year-make-model-cc",
  YEAR_MAKE_MODEL_ENGINE_L: "year-make-model-liters",
  YEAR_MAKE_ENGINE_CC: "year-make-cc",
  YEAR_MAKE_ENGINE_L: "year-make-liters",
  MAKE_ENGINE_CC: "make-cc",
  MAKE_ENGINE_L: "make-liters",
  MAKE: "make",
};

export const MANIFEST_STATUS = {
  DRAFT: 1,
  IN_PROGRESS: 2,
  IN_REVIEW: 3,
  COMPLETED: 4,
};

export const MANIFEST_ENTRY_STATUS = {
  DRAFT: 1,
  IN_PROGRESS: 2,
  APPROVED: 3,
  FLAGGED: 4,
  DENIED: 5,
};

export const EXHUAST_DIAGRAMS = {
  MOTOR_DATA: 1,
  AP_EXHAUST: 2,
};

export const CAR_MAKE_COLORS = {
  ACURA: "#FBB917",
  ALFAROMEO: "#8C2222",
  ASTONMARTIN: "#660033",
  AUDI: "#BA0C2F",
  BENTLEY: "#006A4E",
  BMW: "#003478",
  BUGATTI: "#1A1A1A",
  BUICK: "#DDBB99",
  CADILLAC: "#9A6E6E",
  CHEVROLET: "#D50000",
  CHRYSLER: "#0040FF",
  DODGE: "#FF420E",
  FERRARI: "#D40000",
  FIAT: "#882D17",
  FORD: "#0072C6",
  GENESIS: "#000000",
  GMC: "#225378",
  HONDA: "#E4002B",
  HYUNDAI: "#004080",
  INFINITI: "#891F20",
  JAGUAR: "#000000",
  JEEP: "#0E4C92",
  KIA: "#C21A1A",
  LAMBORGHINI: "#FCE300",
  LANDROVER: "#0033A0",
  LEXUS: "#303F9F",
  LINCOLN: "#2A2B5F",
  MASERATI: "#0C2340",
  MAZDA: "#0000FF",
  MCLAREN: "#FF8700",
  MERCEDESBENZ: "#000000",
  MINI: "#000000",
  MITSUBISHI: "#E60012",
  NISSAN: "#C3002F",
  POLESTAR: "#1A1A1A",
  PORSCHE: "#C41230",
  RAM: "#0C2340",
  ROLLSROYCE: "#FFD700",
  SUBARU: "#002C6A",
  TESLA: "#CC0000",
  TOYOTA: "#EE1D25",
  VOLKSWAGEN: "#005DAA",
  VOLVO: "#003057",
};

export const IMAGES = {
  LOGO_LIGHT: new URL('../assets/images/the-control-center.webp', import.meta.url).href,
  LOGO_PRIMARY: new URL('../assets/images/the-control-center-primary.webp', import.meta.url).href,
  LOGIN_BG: new URL('../assets/images/login-bg.webp', import.meta.url).href,
  BG: new URL('../assets/images/background.webp', import.meta.url).href,
};

export const CONFIG_KEYS = {
  FIREBASE_API_KEY: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  FIREBASE_API_TENANT_ID: import.meta.env.VITE_APP_FIREBASE_TENANT_ID,
  API_URL: import.meta.env.VITE_APP_API_URL,
};
