import React from "react";
import { Box, Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useQuery } from "react-query";
import { QUERIES } from "../utils/constants";
import catalyticConverterService from "../services/catalyticConverterService";

const SavageConverterSelect = (props) => {
  const { data: converters, isLoading: isConvertersLoading } = useQuery([QUERIES.ConverterConnectionsConverters], catalyticConverterService.getAutoCompleteConverters);

  const options = React.useMemo(() => {
    if (!converters?.data?.result?.length) return [];
    
    return converters.data.result.map((cat) => ({
      label: cat.code,
      value: cat.id,
      makeName: cat.make.name,
    }));
  }, [converters?.data?.result]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
    limit: 10,
  });

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={options}
      disabled={isConvertersLoading}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Catalytic Converter"
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      {...props}
    />
  );
};

export default SavageConverterSelect;
