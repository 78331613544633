import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  Box,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { 
  ChevronLeft, 
  ChevronRight, 
  Warning, 
  Error, 
  CheckCircle, 
  ExpandMore, 
  ExpandLess 
} from "@mui/icons-material";
import OverlayLoader from "../ui-components/OverlayLoader";
import manifestService from "../services/manifestService";
import { QUERIES, MANIFEST_ENTRY_STATUS, MANIFEST_STATUS } from "../utils/constants";

export const ManifestDetails = () => {
  const { id } = useParams(); // Get the ID parameter from the URL
  const [expandedRow, setExpandedRow] = useState(null);

  const queryClient = useQueryClient();
  const theme = useTheme();

  const { data: manifest, isLoading } = useQuery([QUERIES.Manifests, id], () => manifestService.get(id));

  const attachVehicles = useMutation(() => manifestService.attachVehicles(id), {
    onSuccess: () => {
      enqueueSnackbar("Attatched vehciles to manifest!", { variant: "success" });
      queryClient.invalidateQueries([QUERIES.Manifests, id]);
    },
    onError: (error) => {
      enqueueSnackbar("Error attaching vehicles to manifest. Please try again.", { variant: "error" });
    },
  });

  const updateManifest = useMutation((statusId) => manifestService.updateManifest(id, { ...manifest?.data?.result, statusId: statusId }), {
    onSuccess: () => {
      enqueueSnackbar("Manifest Updated!", { variant: "success" });
      queryClient.invalidateQueries([QUERIES.Manifests, id]);
    },
    onError: (error) => {
      enqueueSnackbar("Error updating manifest.", { variant: "error" });
    },
  });

  const TableHeaderTypography = styled(Typography)({
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0.17px",
  });

  const handleRowClick = (rowIndex) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowIndex);
    }
  };

  const renderReviewResultChip = (reviewStatusResultId) => {
    if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.APPROVED) {
      return <Chip color="success" label="Approved" sx={{ ml: 1 }} icon={<CheckCircle />} />;
    } else if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.FLAGGED) {
      return <Chip color="warning" label="Flagged" sx={{ ml: 1 }} icon={<Warning />} />;
    } else if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.DENIED) {
      return <Chip color="error" label="Denied" sx={{ ml: 1 }} icon={<Error />} />;
    }
  };

  const getReviewStatus = (reviewStatusResultId) => {
    if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.DRAFT) {
      return "Not Started";
    } else if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.IN_PROGRESS) {
      return "In Review";
    } else {
      return "Completed";
    }
  };

  const renderReviewAction = (reviewStatusResultId, entryId) => {
    if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.DRAFT) {
      return (
        <Button variant="contained" component={Link} to={`/manifests/${id}/review/${entryId}`}>
          START REVIEW
        </Button>
      );
    } else if (reviewStatusResultId === MANIFEST_ENTRY_STATUS.IN_PROGRESS) {
      return (
        <Button variant="contained" endIcon={<ChevronRight />} component={Link} to={`/manifests/${id}/review/${entryId}`}>
          Resume
        </Button>
      );
    } else {
      return (
        <Button variant="outlined" color="primary" component={Link} to={`/manifests/${id}/review/${entryId}`}>
          View Submission
        </Button>
      );
    }
  };

  const isEmpty = (obj) => {
    return obj == null || obj.length === 0;
  };

  const getManifestReviewStatus = (reviewStatusResultId) => {
    if (reviewStatusResultId === MANIFEST_STATUS.COMPLETED) {
      return <Chip label="Completed" color="success" sx={{ width: 100, ml: 1, mb: 1 }} />;
    } else if (reviewStatusResultId === MANIFEST_STATUS.IN_PROGRESS) {
      return <Chip label="In Progress" color="info" sx={{ width: 100, ml: 1, mb: 1 }} />;
    } else if (reviewStatusResultId === MANIFEST_STATUS.IN_REVIEW) {
      return <Chip label="In Review" color="primary" sx={{ width: 100, ml: 1, mb: 1 }} />;
    } else {
      return <Chip label="Draft" color="default" sx={{ width: 100, ml: 1, mb: 1 }} />;
    }
  };

  return (
    <>
      <OverlayLoader isLoading={isLoading || attachVehicles.isLoading} />
      <Grid container px={4} pt={2} pb={7} sx={{ backgroundColor: "white" }}>
        <Grid item xs={12}>
          <Button size="large" sx={{ mb: 1 }} startIcon={<ChevronLeft />} variant="text" color="primary" component={Link} to="/manifests">
            Back
          </Button>
          <Typography variant="h4" color="text.primary">
            Manifest Details
          </Typography>
        </Grid>
      </Grid>
      <Grid container px={4} pt={4}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <>
                  <Typography variant="h4" color="text.primary">
                    Manifest {manifest?.data?.result?.name}
                    {getManifestReviewStatus(manifest?.data?.result?.status?.id)}
                  </Typography>
                  <Box>
                    <Button onClick={async () => await updateManifest.mutateAsync(MANIFEST_STATUS.IN_PROGRESS)}>Activate</Button>
                    <Button onClick={async () => await updateManifest.mutateAsync(MANIFEST_STATUS.IN_REVIEW)}>Review</Button>
                    <Button onClick={async () => await updateManifest.mutateAsync(MANIFEST_STATUS.COMPLETED)}>Complete</Button>
                    <Button onClick={async () => await updateManifest.mutateAsync(MANIFEST_STATUS.DRAFT)}>DRAFT</Button>
                  </Box>
                </>
              }
              action={
                <>
                  <Typography variant="body1" color="text.secondary">
                    Created at{" "}
                    {moment(manifest?.data?.result?.created).format("hh:mm A MM/DD/YYYY")}
                  </Typography>
                  <LoadingButton
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{ mt: 1 }}
                    loading={attachVehicles.isLoading}
                    onClick={() => {
                      attachVehicles.mutate();
                    }}
                  >
                    Attach Vehicles
                  </LoadingButton>
                </>
              }
            />
            <CardContent>
              {manifest && (
                <TableContainer component={Paper} sx={{ mt: 4 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>
                          <TableHeaderTypography>Review Status</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>Review Result</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>Last Updated By</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>YMM</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>Stock</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>VIN</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>Engine</TableHeaderTypography>
                        </TableCell>
                        <TableCell>
                          <TableHeaderTypography>Converter(s)</TableHeaderTypography>
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {manifest?.data?.result?.manifestEntries?.map((row, index) => (
                        <React.Fragment key={index}>
                          <TableRow hover>
                            <TableCell width={20} sx={{ pr: 0 }} onClick={() => handleRowClick(index)} style={{ cursor: "pointer" }}>
                              {expandedRow === index ? <ExpandLess sx={{ mt: 0.5, color: theme.palette.grey[500] }} /> : <ExpandMore sx={{ mt: 0.5, color: theme.palette.grey[500] }} />}
                            </TableCell>
                            <TableCell>{getReviewStatus(row.statusId)}</TableCell>
                            <TableCell>{row.statusId === MANIFEST_ENTRY_STATUS.DRAFT || row.statusId === MANIFEST_ENTRY_STATUS.IN_PROGRESS ? "--" : renderReviewResultChip(row.statusId)}</TableCell>
                            <TableCell>{row?.statusChangedByUser?.name ?? "--"}</TableCell>
                            <TableCell>{isEmpty(row.vehicle?.make) ? "--" : `${row.vehicle?.year} ${row.vehicle?.make} ${row.vehicle?.model}`}</TableCell>
                            <TableCell>{row.stockNumber}</TableCell>
                            <TableCell>{row.vin}</TableCell>
                            <TableCell>{isEmpty(row.vehicle?.engine) ? "--" : row.vehicle?.engine}</TableCell>
                            <TableCell>{row?.manifestEntryCatalyticConverters?.filter((cat) => cat?.enabled)?.length === 0 ? "--" : row.manifestEntryCatalyticConverters?.filter((cat) => cat?.enabled)?.length}</TableCell>
                            <TableCell>{renderReviewAction(row.statusId, row?.id)}</TableCell>
                          </TableRow>
                          {expandedRow === index && (
                            <>
                              <TableRow>
                                <TableCell colSpan={2} sx={{ border: 0 }}></TableCell>
                                <TableCell sx={{ border: 0 }}></TableCell>
                                <TableCell sx={{ border: 0 }}></TableCell>
                                <TableCell sx={{ border: 0 }}>{`${isEmpty(row.vehicle?.year) ? "--" : row.vehicle?.year} / ${isEmpty(row.vehicle?.model) ? "--" : row.vehicle?.model}`}</TableCell>
                                <TableCell sx={{ border: 0 }}></TableCell>
                                <TableCell sx={{ border: 0 }}></TableCell>
                                <TableCell sx={{ border: 0 }}></TableCell>
                                <TableCell sx={{ border: 0 }}>{row.manifestEntryCatalyticConverters?.filter((cat) => cat?.enabled)[0]?.converterConnectionsCatalyticConverter?.code ?? "No Code"}</TableCell>
                                <TableCell sx={{ border: 0 }}></TableCell>
                              </TableRow>
                              {row.manifestEntryCatalyticConverters?.filter((cat) => cat?.enabled)?.slice(1).map((submission) => {
                                var primaryCodeText = submission?.catalyticConverter?.primaryCode;

                                if (submission?.converterConnectionsCatalyticConverter?.code) primaryCodeText = submission?.converterConnectionsCatalyticConverter?.code;
                                if (!primaryCodeText) primaryCodeText = submission?.catalyticConverterPrimaryCode;
                                if (!primaryCodeText) primaryCodeText = "No Code";

                                return (
                                  <TableRow>
                                    <TableCell colSpan={2} sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                    <TableCell sx={{ border: 0 }}>{primaryCodeText}</TableCell>
                                    <TableCell sx={{ border: 0 }}></TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {manifest?.data?.result?.manifestEntries?.length === 0 && (
                <Box display="flex" justifyContent="center" sx={{ mt: 8 }}>
                  <Typography variant="h3">No Manifest Entries Found</Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
