import { Close } from "@mui/icons-material";
import { Box, Button, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ManifestEntryStockChangeStep from "./ManifestEntryStockChangeStep";
import ManifestEntryStockConfirmStep from "./ManifestEntryStockConfirmStep";

const ManifestEntryConverterDetails = ({ manifestEntry, manifestConverter, manifest, initialImage, onClose }) => {
  const [images, setImages] = useState([]);
  const [newManifestEntry, setNewManifestEntry] = useState();
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const STOCK_CHANGE_STEPS = {
    NO_CHANGE: 0,
    STOCK_SELECT: 1,
    STOCK_CONFIRM: 2,
  };
  const [activeStep, setActiveStep] = useState(0);

  const handleNextStepStockNumberChange = (manifestEntryToMigrateTo) => {
    setNewManifestEntry(manifestEntryToMigrateTo);
    setActiveStep(STOCK_CHANGE_STEPS.STOCK_CONFIRM);
  };

  const handlePreviousStep = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    let currentImages = [];
    if (manifestConverter?.frontImage) currentImages.push(manifestConverter?.frontImage);
    if (manifestConverter?.backImage) currentImages.push(manifestConverter?.backImage);
    if (manifestConverter?.codeImage) currentImages.push(manifestConverter?.codeImage);
    if (manifestConverter?.stockTagImage) currentImages.push(manifestConverter?.stockTagImage);
    if (manifestConverter?.images?.length > 0) currentImages = currentImages.concat(manifestConverter?.images);

    setImages(currentImages);
    setSelectedImageIndex(currentImages.findIndex((image) => image.id === initialImage.id));
  }, []);

  const incrementImage = () => {
    if (selectedImageIndex + 1 >= images.length) setSelectedImageIndex(0);
    else setSelectedImageIndex(selectedImageIndex + 1);
  };

  const decrementImage = () => {
    if (selectedImageIndex - 1 < 0) setSelectedImageIndex(images.length - 1);
    else setSelectedImageIndex(selectedImageIndex - 1);
  };

  return (
    <>
      {activeStep === STOCK_CHANGE_STEPS.NO_CHANGE && (
        <>
          <DialogTitle sx={{ pt: 5, px: 5 }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h4" color="text.primary">
                  Final Review: Manifest {manifest?.name}
                </Typography>
                {manifestEntry?.vehicleId && (
                  <Typography variant="h5" color="text.primary" sx={{ mt: 2 }}>
                    {manifestEntry?.vehicle?.year} {manifestEntry?.vehicle?.make} {manifestEntry?.vehicle?.model} {manifestEntry?.vehicle?.trim} {manifestEntry?.vehicle?.engineLiters}L {manifestEntry?.vehicle?.engine}
                  </Typography>
                )}
                <Typography variant="h5" color="text.primary">
                  (STOCK # {manifestEntry?.stockNumber})
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={onClose} style={{ position: "absolute", top: 24, right: 20 }} size="large" color="inherit">
                  <Close sx={{ width: 48, height: 48 }} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ mt: 4, px: 5 }}>
            <Grid container spacing={3}>
              <Grid item justifyContent="center" xs={12}>
                <Carousel showArrows={false} showStatus={false} showThumbs={false} showIndicators={false} selectedItem={selectedImageIndex}>
                  {images.map((image, index) => {
                    return (
                      <Zoom key={`magnifier-image-${index}`}>
                        <img
                          alt="Manifest image"
                          src={image?.url}
                          style={{ width: '100%' }}
                        />
                      </Zoom>
                    );
                  })}
                </Carousel>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={4}>
                  <Typography variant="h4">(#{manifestEntry?.stockNumber})</Typography>
                  <Button variant="contained" sx={{ mt: 2 }} color="error" fullWidth onClick={() => setActiveStep(STOCK_CHANGE_STEPS.STOCK_SELECT)}>
                    Incorrect Stock
                  </Button>
                </Grid>
                <Grid item xs={8} sx={{ alignItems: "center" }}>
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button onClick={() => decrementImage()} size="large">
                      <NavigateBeforeIcon />
                    </Button>
                    {images.map((_image, index) => {
                      return (
                        <Button onClick={() => setSelectedImageIndex(index)}>
                          <FiberManualRecordIcon color={selectedImageIndex === index ? "primary" : "disabled"} />
                        </Button>
                      );
                    })}
                    <Button onClick={() => incrementImage()}>
                      <NavigateNextIcon />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
      {activeStep === STOCK_CHANGE_STEPS.STOCK_SELECT && <ManifestEntryStockChangeStep manifestEntry={manifestEntry} manifest={manifest} images={images} initialImageIndex={selectedImageIndex} onClose={onClose} onBack={handlePreviousStep} onNext={handleNextStepStockNumberChange} />}
      {activeStep === STOCK_CHANGE_STEPS.STOCK_CONFIRM && <ManifestEntryStockConfirmStep manifestEntry={manifestEntry} manifest={manifest} manifestConverter={manifestConverter} newManifestEntry={newManifestEntry} onClose={onClose} onBack={handlePreviousStep} />}
    </>
  );
};

export default ManifestEntryConverterDetails;
