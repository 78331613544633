import "react-medium-image-zoom/dist/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home.jsx";
import { Login } from "./pages/Login.jsx";
import { ManifestDetails } from "./pages/ManifestDetails.jsx";
import { ManifestReview } from "./pages/ManifestReview.jsx";
import { Manifests } from "./pages/Manifests.jsx";
import { Vehicles } from "./pages/Vehicles.jsx";

import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Filler, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import { PgmPrices } from "./pages/PgmPrices.jsx";
import { Users } from "./pages/Users.jsx";
import { CatalyticConverters } from "./pages/CatalyticConverters.jsx";
import PrivateOutlet from "./PrivateOutlet.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import { ApiKeys } from "./pages/ApiKeys.jsx";

ChartJS.register(ArcElement, Tooltip, Legend, Title, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Filler);

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<PrivateOutlet />}>
        <Route path="/" element={<Home />} />
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/manifests" element={<Manifests />} />
        <Route path="/manifests/:id" element={<ManifestDetails />} />
        <Route path="/manifests/:id/review/:entryId" element={<ManifestReview />} />
        <Route path="/pgm-prices" element={<PgmPrices />} />
        <Route path="/converters" element={<CatalyticConverters />} />
        <Route path="/users" element={<Users />} />
        <Route path="/api-keys" element={<ApiKeys />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
}
