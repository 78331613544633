import React from 'react';
import { Autocomplete, TextField, Box, Typography, CircularProgress, createFilterOptions } from '@mui/material';
import { useQuery } from 'react-query';
import { QUERIES } from '../utils/constants';
import catalyticConverterService from '../services/catalyticConverterService';

const SamplesSelect = ({ sx, onChange, value }) => {
  const { data: samplesData, isLoading } = useQuery(
    [QUERIES.CatalyticConverterSamples],
    () => catalyticConverterService.getSamples(),
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );

  const options = React.useMemo(() => 
    samplesData?.data?.result
      ?.filter(sample => 
        (sample.serialNumber?.trim() || sample.markings?.trim())
      )
      ?.map((sample) => ({
        label: `${sample.serialNumber || ''} • ${sample.markings || ''}`.trim(),
        value: sample.id,
        sample: sample
      })) || [], 
    [samplesData?.data?.result]
  );

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => `${option.sample.serialNumber || ''} ${option.sample.markings || ''}`.trim(),
    limit: 100,
  });

  return (
    <Autocomplete
      sx={sx}
      options={options}
      loading={isLoading}
      value={value}
      onChange={onChange}
      filterOptions={filterOptions}
      multiple
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Sample"
          placeholder="Search samples..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props} sx={{ borderBottom: '1px solid #e0e0e0', marginBottom: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, paddingBottom: 2 }}>
            <Box
              component="img"
              src={`https://i.ibb.co/MBtZhBS/cat-example-${option.imageNumber}.jpg`}
              sx={{
                width: 50,
                height: 50,
                flexShrink: 0,
                borderRadius: 1,
                objectFit: 'cover'
              }}
              alt="Catalytic converter sample"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1" component="div">
                <span style={{ fontWeight: 'bold' }}>Serial Number:</span> {option.sample.serialNumber || ''}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                <span style={{ fontWeight: 'bold' }}>Markings:</span> {option.sample.markings || ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      getOptionLabel={(option) => option.label || ''}
    />
  );
};

export default SamplesSelect;
