import { ChevronRight, DirectionsCar } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from "@mui/material";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import manifestService from "../services/manifestService";
import { CAR_MAKE_COLORS, MANIFEST_ENTRY_STATUS, QUERIES } from "../utils/constants";
import { NumericFormat }  from "react-number-format";

export const ApprovedVehiclesCard = () => {
  const { data: manifestEntryVehicles, isFetching: vehiclesLoading } = useQuery([QUERIES.ManifestEntryVehicles, "overview"], () => {
    var test = {
      manifestEntryStatusIds: [MANIFEST_ENTRY_STATUS.APPROVED],
      size: 99999,
    };
    return manifestService.getEntryVehicles(test);
  });

  const options = {
    responsive: true,
    cutout: "80%",
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
        labels: {
          usePointStyle: true,
        },
        maxHeight: 6,
      },
    },
  };

  const vehicleMakeBreakdown =
    manifestEntryVehicles?.data?.result &&
    manifestEntryVehicles?.data?.result?.reduce((a, b) => {
      if (a[b.vehicle.make] === undefined) {
        a[b.vehicle.make] = 1;
      } else {
        a[b.vehicle.make] = a[b.vehicle.make] + 1;
      }
      return a;
    }, {});

  const vehicleData = vehicleMakeBreakdown && {
    labels: Object.keys(vehicleMakeBreakdown),
    datasets: [
      {
        label: "Approved Vehicles",
        data: Object.values(vehicleMakeBreakdown),
        backgroundColor: Object.keys(vehicleMakeBreakdown).map((key) => CAR_MAKE_COLORS[key]),
        borderWidth: 1,
      },
    ],
  };

  const approvedVehicles = manifestEntryVehicles?.data?.result?.length;
  const approvedvehiclesInLastWeek = manifestEntryVehicles?.data?.result?.filter((vehicle) => {
    const today = new moment();
    const lastWeek = new moment().subtract(7, "days");
    const vehicleDate = new moment(vehicle.lastUpdated);
    return vehicleDate.isBetween(lastWeek, today);
  }).length;

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" color="text.primary">
            Approved Vehicles
          </Typography>
        }
        action={
          <Button component={Link} to="/vehicles" endIcon={<ChevronRight />} variant="outlined">
            View
          </Button>
        }
      />
      <CardContent>
        {vehiclesLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 250,
            }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <DirectionsCar color="primary" />
                <Typography variant="subtitle1">
                  <NumericFormat value={approvedvehiclesInLastWeek} displayType="text" thousandSeparator={true} /> Added this week
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Box>
                <Typography variant="h5" textAlign="center">
                  Total Approved
                </Typography>
                <Typography variant="h3" textAlign="center">
                  <NumericFormat value={approvedVehicles} displayType="text" thousandSeparator={true} />
                </Typography>
              </Box>
            </Grid>
            <Grid item md={8} xs={12}>
              {vehicleData && <Doughnut data={vehicleData} options={options} height={200} />}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
