import { ChevronRight } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import manifestService from "../services/manifestService";
import { QUERIES } from "../utils/constants";
import { NumericFormat } from "react-number-format";

const startDifference = 30;

export const CatalyticConvertersCard = () => {
  const last30DaysRange = {
    start: moment().subtract(startDifference, "days").startOf("day"),
    end: moment().endOf("day"),
  };

  const { data: manifestEntryVehicles, isFetching: vehiclesLoading } = useQuery([QUERIES.ManifestEntryVehicles, "catalytic-converters"], () => {
    const test = {
      start: last30DaysRange.start,
      end: last30DaysRange.end,
    };
    return manifestService.getEntryVehicles(test);
  });

  const vehicleMakeBreakdown = manifestEntryVehicles?.data?.result;

  const vehicleFinal = vehicleMakeBreakdown?.filter((cat) => {
    const vehicleDate = moment(cat.created);
    return vehicleDate.isBetween(last30DaysRange.start, last30DaysRange.end);
  });

  const last7Days = vehicleFinal?.filter((cat) => {
    const last7DaysRange = {
      start: moment().subtract(7, "days").startOf("day"),
      end: moment().endOf("day"),
    };

    const vehicleDate = moment(cat.created);
    return vehicleDate.isBetween(last7DaysRange.start, last7DaysRange.end);
  });

  // Create an array of labels for the last 30 days
  const labels = [];
  let currentDate = moment().subtract(startDifference, "days").startOf("day");
  while (currentDate.isSameOrBefore(moment().endOf("day"))) {
    labels.push(currentDate?.format("MMM DD"));
    currentDate = currentDate.add(1, "day");
  }

  // Create barData using the last 30 days labels and data
  const barData = {
    labels,
    datasets: [
      {
        label: "Converters",
        data: labels.map((label) => {
          const date = moment(label, "MMM DD");
          const filteredData = vehicleFinal?.filter((cat) => {
            const vehicleDate = moment(cat.created);
            return vehicleDate.isSame(date, "day");
          });
          return filteredData?.length || 0;
        }),
        backgroundColor: "rgba(21, 122, 255, 0.2)",
        hoverBackgroundColor: "rgba(21, 122, 255, 1)",
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  const groupedCounts =
    vehicleMakeBreakdown &&
    vehicleMakeBreakdown.reduce((result, item) => {
      const externalId = item.user?.externalId;
      const currentDate = new Date(item.created);
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      // Check if the record is within the last 7 days
      if (currentDate >= sevenDaysAgo && currentDate <= today) {
        if (!result[externalId]) {
          result[externalId] = {
            user: item.user,
            last7Days: 0,
            total: 0,
          };
        }
        result[externalId].last7Days++;
      }

      // Increment the total count for the user
      if (!result[externalId]) {
        result[externalId] = {
          user: item.user,
          last7Days: 0,
          total: 0,
        };
      }
      result[externalId].total++;

      return result;
    }, {});

  const groupedArray =
    groupedCounts &&
    Object.values(groupedCounts).map((userData) => ({
      user: userData.user,
      last7Days: userData.last7Days,
      total: userData.total,
    }));

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" color="text.primary">
            Catalytic Converters
          </Typography>
        }
        action={
          <Button endIcon={<ChevronRight />} variant="outlined">
            View
          </Button>
        }
      />
      <CardContent>
        {vehiclesLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box px={1} sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1" color="text.secondary">
                  Added in last 7 days
                </Typography>
                <Typography variant="h5" color="text.primary">
                  <NumericFormat value={last7Days?.length} displayType="text" thousandSeparator={true} />
                </Typography>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1" color="text.secondary">
                  Added in last 30 days
                </Typography>
                <Typography variant="h5" color="text.primary">
                  <NumericFormat value={vehicleFinal?.length} displayType="text" thousandSeparator={true} />
                </Typography>
              </Box>
            </Box>
            <Box py={2}>
              <Bar data={barData} options={barOptions} height={100} />
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Last 7 Days</TableCell>
                  <TableCell align="right">Last 30 Days</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedArray &&
                  groupedArray
                    .sort((a, b) => b.total - a.total)
                    .map((item, index) => {
                      return (
                        <TableRow key={item?.user?.externalId || `user-${index}`}>
                          <TableCell>{item?.user?.name}</TableCell>
                          <TableCell align="right">
                            <NumericFormat value={item.last7Days} displayType="text" thousandSeparator={true} />
                          </TableCell>
                          <TableCell align="right">
                            <NumericFormat value={item.total} displayType="text" thousandSeparator={true} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </>
        )}
      </CardContent>
    </Card>
  );
};
